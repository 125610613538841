import { storeToRefs } from 'pinia';
import { useGamesStore } from '~/stores/games';
import {
  GAME_PROVIDERS,
  GAME_CATEGORIES,
} from '~/constants/general';

export default defineNuxtRouteMiddleware(async (to) => {
  const provider = to.params.gameProvider as string;
  const gamesStore = useGamesStore();
  const { fetchGameProvidersForSSR } = gamesStore;
  const { getGameProviders } = storeToRefs(gamesStore);

  if (!getGameProviders.value.length) {
    await fetchGameProvidersForSSR();
  }

  const gameProvidersList = computed(() => {
    return [
      { urlMapping: GAME_PROVIDERS.all.toLowerCase() },
      ...getGameProviders.value,
    ];
  });
  const category = (to.params.gameCategory as string).replaceAll('-', '_');
  const gameCategoriesList = computed(() => {
    return Object.values(GAME_CATEGORIES).map((item) =>
      item.toLowerCase(),
    );
  });

  if (
    !gameProvidersList.value.some((item) => item.urlMapping === provider) ||
    !gameCategoriesList.value.some((item) => item === category)
  ) {
    throw createError({
      statusCode: 404,
      message: 'Page not found',
      fatal: true,
    });
  }
});
